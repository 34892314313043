import {
  interpreting,
  translation,
  consulting,
} from "../../assets/home-page-images";
import { useGlobalState } from "../contexts/global-contexts";
import { useIsVisible } from "../utils/conditionallyAnimate";
import { useRef } from "react";

interface ServicesProps {
  idx: number;
  title: string;
  description: string;
}

const Services = (props: ServicesProps) => {
  const { idx, title, description } = props;
  const servicesImages = [interpreting, translation, consulting];
  const { state } = useGlobalState();
  const {
    hasSeenServicesElement1,
    hasSeenServicesElement2,
    hasSeenServicesElement3,
  } = state;

  const ref = useRef<HTMLDivElement>(null);
  useIsVisible(ref, idx);

  let isVisible;

  switch (idx) {
    case 0:
      isVisible = hasSeenServicesElement1;
      break;
    case 1:
      isVisible = hasSeenServicesElement2;
      break;
    case 2:
      isVisible = hasSeenServicesElement3;
      break;
  }

  let ourBlogText;
  switch (state.language) {
    case "English":
      ourBlogText = "our blog.";
      break;
    case "Español":
      ourBlogText = "nuestro blog.";
      break;
    case "中文":
      ourBlogText = "我们的博客！";
      break;
  }

  return (
    <div className="p-4">
      {idx % 2 === 0 ? (
        <div
          ref={ref}
          className={`transition-all
        ease-in
        duration-500
        ${
          isVisible ? "opacity-100 lg:pr-40" : "opacity-0 lg:pr-80"
        } flex flex-col lg:flex-row justify-center items-center p-4 drop-shadow`}
        >
          <div
            id="outer-ring"
            className="bg-shaoyao-green h-44 w-44 lg:h-60 lg:w-60 rounded-full leading-10 shadow-2xl"
          >
            <div
              id="inner-image"
              className="h-[9.5rem] w-[9.5rem] lg:h-[13.5rem] lg:w-[13.5rem] overflow-hidden rounded-full m-3 shadow-lg"
            >
              <img
                src={servicesImages[idx]}
                alt={`${servicesImages[idx]}`}
              ></img>
            </div>
          </div>
          <div
            id="connecting-bar"
            className="bg-transparent lg:bg-shaoyao-green h-14 lg:h-20 w-10 -m-3 z-0"
          ></div>
          <div
            id="service-box"
            className="bg-shaoyao-green w-full lg:w-[42rem] rounded-3xl shadow-2xl p-4"
          >
            <div className="w-full text-center text-lg lg:text-xl font-extrabold p-0 lg:p-4">
              {title}
            </div>
            <div className="w-full text-center text-normal lg:text-lg lg:p-2">
              {description}
              {idx === 2 ? " " : ""}
              {idx === 2 ? (
                <a
                  href="/blog"
                  className="underline text-blue-300 hover:text-blue-800"
                >
                  {ourBlogText}
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={ref}
          className={`transition-all
          ease-in
          duration-500
          ${
            isVisible ? "opacity-100 lg:pl-40" : "opacity-0 lg:pl-80"
          } flex flex-col-reverse lg:flex-row justify-center items-center p-4`}
        >
          <div className="bg-shaoyao-light-green w-full lg:w-[42rem] rounded-3xl shadow-2xl z-0 p-4">
            <div className="w-full text-center text-lg lg:text-xl font-extrabold p-0 lg:p-4">
              {title}
            </div>
            <div className="w-full text-center text-normal lg:text-lg lg:p-2">
              {description}
            </div>
          </div>
          <div className="bg-transparent lg:bg-shaoyao-light-green h-14 lg:h-20 w-10 -m-3 z-0"></div>
          <div
            id="outer-ring"
            className="bg-shaoyao-light-green h-44 w-44 lg:h-60 lg:w-60 rounded-full leading-10 shadow-2xl"
          >
            <div
              id="inner_image"
              className="h-[9.5rem] w-[9.5rem] lg:h-[13.5rem] lg:w-[13.5rem] overflow-hidden rounded-full m-3 shadow-lg"
            >
              <img
                src={servicesImages[idx]}
                alt={`${servicesImages[idx]}`}
              ></img>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
