import { useGlobalState } from "../contexts/global-contexts";
import { RefObject, useEffect, useState } from "react";

//TODO: Fix ref typing
export function useIsVisible(ref: RefObject<HTMLDivElement>, refId: number) {
  const { state, setState } = useGlobalState();
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    if (ref.current != null) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    if (isIntersecting) {
      switch (refId) {
        case 0:
          setState({ ...state, hasSeenServicesElement1: true });
          break;
        case 1:
          setState({ ...state, hasSeenServicesElement2: true });
          break;
        case 2:
          setState({ ...state, hasSeenServicesElement3: true });
          break;
      }
    }
  }, [isIntersecting]);
}
