import Logo from "./logo";
import facebook_logo from "../assets/resized/facebook_logo_bw.webp";
import linkedin_logo from "../assets/resized/linkedin_logo_bw.webp";
import { useGlobalState } from "./contexts/global-contexts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const Footer = () => {
  const navigate = useNavigate();
  const { state } = useGlobalState();
  const { language } = state;
  const [policy, setPolicy] = useState({ privacy: "", cancellation: "" });

  const handlePrivacyNavigation = (policyType: string) => {
    window.scrollTo(0, 0);
    if (policyType === "privacy") navigate("/privacy");
    if (policyType === "cancellation") navigate("/cancellation");
  };

  useEffect(() => {
    switch (language) {
      case "English":
        setPolicy({
          privacy: "Privacy Notice",
          cancellation: "Cancellation Notice",
        });
        break;
      case "中文":
        setPolicy({ privacy: "隐私声明", cancellation: "取消政策" });
        break;
      case "Español":
        setPolicy({
          privacy: "AVISO DE PRIVACIDAD",
          cancellation: "POLÍTICA DE CANCELACIONES",
        });
        break;
    }
  }, [language]);

  return (
    <div className="bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] shadow-inner">
      <div
        id="footer"
        className="flex items-center p-4 space-x-2 md:space-x-20 md:justify-center font-Erode text-white"
      >
        <div
          id="footer-left-elements"
          className="flex-col w-2/3 md:w-auto -space-y-3"
        >
          <Logo displayContactInfo={true} />
        </div>
        <div id="footer-right-elements" className="w-1/3 md:w-auto">
          <div className="flex justify-center space-x-2 md:space-x-8 mb-6">
            <a href="https://www.facebook.com/shaoyaoconsulting">
              <img
                id="facebook-logo"
                className="w-10 lg:w-12"
                src={facebook_logo}
                alt="facebook-logo"
              ></img>
            </a>
            <a href="https://www.linkedin.com/company/shaoyao">
              <img
                id="linkedin-logo"
                className="w-10 lg:w-12"
                src={linkedin_logo}
                alt="linkedin-logo"
              ></img>
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center text-white pb-4 space-x-4">
        <button
          aria-label="navigate to privacy policy"
          className="underline"
          onClick={() => handlePrivacyNavigation("privacy")}
        >
          {policy.privacy}
        </button>
        <button
          aria-label="navigate to cancellation policy"
          className="underline"
          onClick={() => handlePrivacyNavigation("cancellation")}
        >
          {policy.cancellation}
        </button>
      </div>
    </div>
  );
};

export default Footer;
