import Logo from "./logo";
import HamburgerMenu from "./hamburger-menu";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useGlobalState } from "./contexts/global-contexts";
import { useLocation } from "react-router-dom";
import { loadInitialBlogData } from "./utils/fetch-blog-data";

export const Header = () => {
  const { state, setState } = useGlobalState();
  const { language, languageList, getBlogDataStatus, blogData } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [currentHeaderIdx, setCurrentHeaderIdx] = useState(
    location.pathname === "/" ? 0 : 2
  );
  const [showLanguageDropDown, setShowLanguageDropDown] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [headers, setHeaders] = useState([""]);

  /*
  Sets current header to be underlined and navigates to home/blog
  */
  const handleRouteChange = (idx: number) => {
    idx === 1 ? setCurrentHeaderIdx(0) : setCurrentHeaderIdx(idx);
    if ((currentHeaderIdx === 2 && idx === 1) || idx === 1) {
      setState({ ...state, servicesClicked: true });
    }

    if (idx === 0) {
      navigate("/");
    } else if (idx === 1) {
      navigate("/");
    } else if (idx === 2) {
      navigate("/blog");
    }
    if (showHamburgerMenu) {
      setShowHamburgerMenu(false);
    }
  };

  /*
  Displays language dropdown menu
  */
  const handleShowDropDown = () => {
    setShowLanguageDropDown(!showLanguageDropDown);
  };

  /*
  Handles language selection
  */
  const handleLanguageSelection = (selectedLanguage: string) => {
    const newLanguageList = [];
    for (let i = 0; i < languageList.length; i++) {
      if (languageList[i] === selectedLanguage) {
        newLanguageList.push(language);
      } else {
        newLanguageList.push(languageList[i]);
      }
    }
    const languageState = {
      language: selectedLanguage,
      languageList: newLanguageList,
    };
    const newState = {
      ...state,
      ...languageState,
    };
    setState(newState);
    setShowLanguageDropDown(false);
    window.localStorage.setItem("SHAOYAO_STATE", JSON.stringify(languageState));
  };

  const handleShowHamburgerMenu = () => {
    setShowHamburgerMenu(!showHamburgerMenu);
  };

  /*
    Gets initial blog data and reads any cached language state
  */
  useEffect(() => {
    const setCurrentLanguage = () => {
      switch (language) {
        case "English":
          setHeaders(["Home", "Services", "Blog"]);
          break;
        case "中文":
          setHeaders(["首页", "服务", "博客"]);
          break;
        case "Español":
          setHeaders(["Inicio", "Servicios", "Blog"]);
          break;
      }
    };
    const getInitialBlogData = async (languageData?: {
      language: string;
      languageList: string[];
    }) => {
      const newBlogData = await loadInitialBlogData();
      if (!languageData) {
        if (newBlogData) {
          setState({
            ...state,
            blogData: newBlogData,
            getBlogDataStatus: "Loaded",
          });
        } else
          setState({
            ...state,
            getBlogDataStatus: "Failed",
          });
      } else {
        if (newBlogData) {
          setState({
            ...state,
            language: languageData.language,
            languageList: languageData.languageList,
            blogData: newBlogData,
            getBlogDataStatus: "Loaded",
          });
        } else
          setState({
            ...state,
            language: languageData.language,
            languageList: languageData.languageList,
            getBlogDataStatus: "Failed",
          });
      }
    };
    const savedLanguageData = window.localStorage.getItem("SHAOYAO_STATE");
    if (getBlogDataStatus === "Not Loaded") {
      if (savedLanguageData) getInitialBlogData(JSON.parse(savedLanguageData));
      else getInitialBlogData();
    }
    setCurrentLanguage();
  }, [language]);

  useEffect(() => {
    if (blogData) {
      const fetchBlogImageData: any = [];
      blogData.forEach((data, idx) => {
        const { large, medium, small } =
          data.header_image_data.data.attributes.formats;
        fetchBlogImageData.push(large.url, medium.url, small.url);
      });
    }
  }, [blogData]);

  /* 
   Handles the event where the user clicks outside of the area of the dropdown menu 
  */
  useEffect(() => {
    const closeDropdownOnClickHandler = (e: any) => {
      if (!menuRef?.current?.contains(e.target)) {
        setShowLanguageDropDown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdownOnClickHandler);
    return () => {
      document.body.removeEventListener(
        "mousedown",
        closeDropdownOnClickHandler
      );
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setState({
      ...state,
      hasSeenServicesElement1: false,
      hasSeenServicesElement2: false,
      hasSeenServicesElement3: false,
    });
  }, []);

  return (
    <div>
      {showHamburgerMenu && (
        <HamburgerMenu
          language={language}
          headers={headers}
          setHamburgerDisplay={handleShowHamburgerMenu}
          changeRoute={handleRouteChange}
          languageSelection={handleLanguageSelection}
          languageList={languageList}
        />
      )}
      {/* Logo and business name */}
      <div className="bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] w-full h-full flex justify-between items-center text-white drop-shadow-xl">
        <div className="flex w-1/2 h-full p-4">
          <button onClick={() => handleRouteChange(0)}>
            <Logo displayContactInfo={false} />
          </button>
        </div>
        {/* Home, Services, Blog */}
        <div className=" w-1/2 h-27 p-4 font-semibold items-center hidden lg:flex">
          {headers.map((header, idx) => {
            return (
              <button
                aria-label={`navigate to ${header}`}
                key={String(header) + String(idx)}
                className="w-1/4 text-xl font-Erode"
              >
                <div
                  className={
                    currentHeaderIdx === idx ? "border-b-2 w-1/2" : "w-1/2"
                  }
                  onClick={() => handleRouteChange(idx)}
                >
                  {header}
                </div>
              </button>
            );
          })}
          {/* Language toggle */}
          <div ref={menuRef}>
            <button aria-label="language dropdown" onClick={handleShowDropDown}>
              <div
                className={`w-40 h-10 flex justify-center bg-shaoyao-blue border-none items-center ${
                  showLanguageDropDown ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <div className="pr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.25em"
                    viewBox="0 0 496 512"
                    fill="#FFFFFF"
                  >
                    <path d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z" />
                  </svg>
                </div>
                <div>{language}</div>
              </div>
            </button>
            {/*Dropdown menu*/}
            {showLanguageDropDown && (
              <div className="absolute w-40 float-right">
                {languageList.map((language, idx) => {
                  return (
                    <button
                      aria-label={`change language to ${language}`}
                      key={String(language) + String(idx)}
                      className={`bg-shaoyao-blue w-full p-2 ${
                        languageList.length - 1 === idx && "rounded-b-lg"
                      }`}
                      onClick={() => {
                        handleLanguageSelection(language);
                      }}
                    >
                      {language}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {/* Hamburger Menu */}
        <button
          aria-label="menu dropdown"
          className="p-5 lg:hidden"
          onClick={handleShowHamburgerMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 448 512"
            fill="#FFFFFF"
          >
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Header;
