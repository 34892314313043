import shaoyao_logo from "../assets/shaoyao_logo.webp";

interface LogoProps {
  displayContactInfo: boolean;
}

export const Logo = (props: LogoProps) => {
  const { displayContactInfo } = props;

  return (
    <div id="shaoyao-logo-elements" className="flex">
      <img
        id="shaoyao-logo-image"
        className="w-20 h-20"
        src={shaoyao_logo}
        alt="shaoyao logo"
      ></img>
      <div id="shaoyao-logo-text" className="flex-col pl-2">
        <div className="flex justify-center text-3xl pt-3 font-Erode font-bold">
          SHAOYAO
        </div>
        <div className="flex justify-center text-sm font-Erode font-bold">
          CONSULTING
        </div>
        {displayContactInfo && (
          <div id="contact-text-elements" className="text-xs">
            <div className="flex justify-center">
              info@shaoyaoconsulting.com
            </div>
            <div className="flex justify-center">+52 55 6540 9870</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Logo;
