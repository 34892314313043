import { useEffect, useState, useRef } from "react";
import {
  hero_image_1_300,
  hero_image_2_300,
  hero_image_3_300,
  hero_image_4_300,
  hero_image_5_300,
  hero_image_6_300,
} from "../../assets/home-page-images/resized/300";
import {
  hero_image_1_1000,
  hero_image_2_1000,
  hero_image_3_1000,
  hero_image_4_1000,
  hero_image_5_1000,
  hero_image_6_1000,
} from "../../assets/home-page-images/resized/1000";
import {
  hero_image_1_1380,
  hero_image_2_1380,
  hero_image_3_1380,
  hero_image_4_1380,
  hero_image_5_1380,
  hero_image_6_1380,
} from "../../assets/home-page-images/resized/1380";
import {
  hero_image_1_1670,
  hero_image_2_1670,
  hero_image_3_1670,
  hero_image_4_1670,
  hero_image_5_1670,
  hero_image_6_1670,
} from "../../assets/home-page-images/resized/1670";
import {
  hero_image_1_1920,
  hero_image_2_1920,
  hero_image_3_1920,
  hero_image_4_1920,
  hero_image_5_1920,
  hero_image_6_1920,
} from "../../assets/home-page-images/resized/1920";
import {
  hero_image_1_2048,
  hero_image_2_2048,
  hero_image_3_2048,
  hero_image_4_2048,
  hero_image_5_2048,
  hero_image_6_2048,
} from "../../assets/home-page-images/resized/2048";
import Services from "./services";
import HeroBox from "./hero-box";
import FooterCallToAction from "./footer-call-to-action-box";
import PageComponent from "../page-component";
import { useGlobalState } from "../contexts/global-contexts";

const Landing = () => {
  const { state, setState } = useGlobalState();
  const { language, servicesClicked } = state;
  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const homePageImages300 = [
    hero_image_1_300,
    hero_image_2_300,
    hero_image_3_300,
    hero_image_4_300,
    hero_image_5_300,
    hero_image_6_300,
  ];
  const homePageImages1000 = [
    hero_image_1_1000,
    hero_image_2_1000,
    hero_image_3_1000,
    hero_image_4_1000,
    hero_image_5_1000,
    hero_image_6_1000,
  ];
  const homePageImages1380 = [
    hero_image_1_1380,
    hero_image_2_1380,
    hero_image_3_1380,
    hero_image_4_1380,
    hero_image_5_1380,
    hero_image_6_1380,
  ];
  const homePageImages1670 = [
    hero_image_1_1670,
    hero_image_2_1670,
    hero_image_3_1670,
    hero_image_4_1670,
    hero_image_5_1670,
    hero_image_6_1670,
  ];
  const homePageImages1920 = [
    hero_image_1_1920,
    hero_image_2_1920,
    hero_image_3_1920,
    hero_image_4_1920,
    hero_image_5_1920,
    hero_image_6_1920,
  ];
  const homePageImages2048 = [
    hero_image_1_2048,
    hero_image_2_2048,
    hero_image_3_2048,
    hero_image_4_2048,
    hero_image_5_2048,
    hero_image_6_2048,
  ];
  const ServicesText: {
    staticText: string[];
    title: string[];
    description: string[];
  } = {
    staticText: ["", "", ""],
    title: ["", "", ""],
    description: ["", "", ""],
  };
  const serviceRef = useRef<null | HTMLDivElement>(null);

  switch (language) {
    case "English":
      ServicesText.staticText = [
        "The leading firm in building bridges of understanding between Mexico and China.",
        "We are experts in providing interpreting, translation and consulting services in Chinese, Spanish and English. Our sole mission: to help our clients achieve borderless communication.",
        "We're the right fit if you need:",
      ];
      ServicesText.title = [
        "Professional Interpreting",
        "Specialized Translation",
        "Cross-Cultural Consulting",
      ];
      ServicesText.description = [
        "Simultaneous, consecutive and/or accompanying Chinese/Spanish/English interpreting to achieve clear and effective communication across technical, commercial, academic, media--related, artistic, and ceremonial settings.",
        "Simple or certified translation of technical, legal, and corporate documents, integrating state-of-the-art technology to offer solutions that adapt to the requirements and delivery times of each case.",
        "Design and delivery of conferences, courses, workshops, and webinars on business and academic topics focused on China and her connections with Latin America. Discover our content through",
      ];
      break;
    case "中文":
      ServicesText.staticText = [
        "在墨西哥和中国之间搭建 理解桥梁的领先企业。",
        "我们专注提供中西英口译、笔译和顾问服务。我们的唯一使命 : 协助客户实现无界沟通。",
        "我们是您最佳选择，为您提供以下服务：",
      ];
      ServicesText.title = ["专业口译", "专业笔译", "跨文化顾问"];
      ServicesText.description = [
        "中西英同声传译、︀交替传译和/或陪同翻译译，在技术、︀商业、︀学术、︀媒体、︀艺术和 礼仪场合实现清晰有效的沟通。",
        "技术、︀法律和公司文件的简易笔译或认证笔译，结合最先进的技术，提供适应每种 情况的要求和交付时间的解决方案。",
        "我们设计并举办以中拉关系为主题的商务和学术讲座、︀课程、︀研讨会和在线培训。为了了解更多内容欢迎浏览",
      ];
      break;
    case "Español":
      ServicesText.staticText = [
        "La firma líder en crear puentes de entendimiento entre México y China.",
        "Somos expertos en proporcionar servicios de interpretación, traducción y consultoría en chino, español e inglés. Nuestra única misión: ayudar a nuestros clientes a alcanzar una comunicación sin fronteras.",
        "Somos tu mejor opción si necesitas:",
      ];
      ServicesText.title = [
        "Interpretación profesional",
        "Traducción especializada",
        "Consultoría intercultural",
      ];
      ServicesText.description = [
        "Interpretación simultánea, consecutiva y/o de acompañamiento chino-español-inglés para alcanzar una comunicación clara y efectiva en contextos técnicos, comerciales, académicos, mediáticos, artísticos y protocolares.",
        "Traducción simple o certificada de documentos técnicos, legales, y corporativos, integrando tecnología de punta para ofrecer soluciones que se adapten a los requisitos y tiempos de entrega de cada caso.",
        "Diseño e impartición de conferencias, cursos, talleres y webinarios sobre temas empresariales y académicos centrados en China y sus vínculos con Latinoamérica. Descubre nuestro contenido a través de ",
      ];
      break;
  }

  useEffect(() => {
    const nextSlide = () => {
      setCurrentImageIdx(
        currentImageIdx === homePageImages2048.length - 1
          ? 0
          : currentImageIdx + 1
      );
    };
    setTimeout(nextSlide, 5000);
  }, [currentImageIdx]);

  useEffect(() => {
    const downScrollToServices = () => {
      serviceRef.current?.scrollIntoView({ behavior: "smooth" });
      setState({ ...state, servicesClicked: false });
    };
    if (servicesClicked) {
      downScrollToServices();
    }
  }, [servicesClicked]);

  return (
    <PageComponent>
      <div className="flex-col justify-center items-center text-white">
        <div
          id="hero-section-container"
          className="lg:max-h-[calc(100vh-6rem)] lg:flex lg:items-center lg:justify-center"
        >
          <div className="grid grid-rows-7 grid-cols-7">
            {homePageImages2048.map((homePageImage2048, idx) => {
              return (
                <img
                  key={String(homePageImage2048[idx]) + String(idx)}
                  srcSet={`${homePageImages300[idx]} 300w,
                ${homePageImages1000[idx]} 1000w,
                ${homePageImages1380[idx]} 1380w,
                ${homePageImages1670[idx]} 1670w,
                ${homePageImages1920[idx]} 1920w,
                ${homePageImage2048} 2048w`}
                  sizes="100vw"
                  src={homePageImage2048}
                  alt="Locations in Mexico and China"
                  className={`aspect-video object-top object-cover w-full h-full row-start-1 row-end-7 col-start-1 col-end-8 transition-opacity duration-1000 ease-in-out -z-10 ${
                    idx === currentImageIdx ? "opacity-100" : "opacity-0"
                  }`}
                />
              );
            })}
            <div className="hidden lg:flex col-start-5 row-start-2 col-span-2 row-span-4 h-full">
              <HeroBox language={language} />
            </div>
          </div>
          <div className="lg:hidden">
            <HeroBox language={language} />
          </div>
        </div>
        <div className="lg:pt-20 pb-4 w-full bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)]">
          {ServicesText.staticText.map((text, idx) => {
            return (
              <div
                key={String(text) + String(idx)}
                className="flex"
                ref={serviceRef}
              >
                <div className="hidden lg:flex w-1/4" />
                <div
                  className={`p-4 m-4 text-center w-full lg:w-[80%] ${
                    idx === 0 ? "text-2xl lg:text-4xl" : "text-xl lg:text-2xl"
                  } `}
                >
                  {text}
                </div>
                <div className="hidden lg:flex w-1/4" />
              </div>
            );
          })}
          {ServicesText.title.map((title, idx) => {
            return (
              <Services
                key={String(title) + String(idx)}
                title={title}
                description={ServicesText.description[idx]}
                idx={idx}
              />
            );
          })}
          <FooterCallToAction />
        </div>
      </div>
    </PageComponent>
  );
};

export default Landing;
