import { useEffect, useState } from "react";
import { useGlobalState } from "../contexts/global-contexts";
import { Link } from "react-router-dom";
import { BlogState } from "../interface/interface-type";

export const FeaturedPostsSection = () => {
  const { state } = useGlobalState();
  const { blogData, language } = state;
  const [preFoldFeaturedPostData, setPreFoldFeaturedPostData] =
    useState<BlogState[]>();
  const [postFoldFeaturedPostData, setPostFoldFeaturedPostData] =
    useState<BlogState[]>();
  const [sectionHeaderText, setSectionHeaderText] = useState("");

  useEffect(() => {
    if (blogData) {
      const blogPreloadData = [...blogData];
      // Pop most recent post
      blogPreloadData.shift();

      // Find and set 3 featured posts
      setPreFoldFeaturedPostData([
        blogPreloadData.filter(
          (postData) => postData.featured_post_1 === true
        )[0],
        blogPreloadData.filter(
          (postData) => postData.featured_post_2 === true
        )[0],
        blogPreloadData.filter(
          (postData) => postData.featured_post_3 === true
        )[0],
      ]);

      // Pop 3 featured posts from remaining entries displayed post-fold
      const postFoldFeaturedPostsData = blogPreloadData.filter(
        (postData) =>
          postData.featured_post_1 !== true &&
          postData.featured_post_2 !== true &&
          postData.featured_post_3 !== true
      );
      setPostFoldFeaturedPostData(blogPreloadData);
    }
  }, [blogData]);

  useEffect(() => {
    switch (language) {
      case "English":
        setSectionHeaderText("Featured Posts");
        break;
      case "Español":
        setSectionHeaderText("Publicaciones destacadas");
        break;
      case "中文":
        setSectionHeaderText("精选博客");
        break;
    }
  }, [language]);

  return (
    <div id="featured-posts-elements" className="text-white">
      <div className="flex justify-center text-xl font-semibold">
        {sectionHeaderText}
      </div>
      <div className="flex flex-wrap md:flex-row p-8 justify-center items-center">
        {preFoldFeaturedPostData?.map((post, idx) => {
          return (
            <Link
              /* Format is /blog/:id/:title-separated-by-hyphens */
              to={`/blog/${post?.id}/${post?.title.replaceAll(" ", "-")}/`}
              key={String(post?.id) + idx}
              className="md:w-4/6 md:max-w-sm flex flex-col items-center space-y-4 pb-4"
            >
              <img
                className="w-[19rem] h-[9.5rem] rounded-xl object-cover"
                src={post?.small_image_url}
                alt={post?.header_image_alt_text}
              ></img>
              <div className="h-12 max-w-lg overflow-hidden">
                <strong>{post?.title}</strong>
                <br />
                {post?.description}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturedPostsSection;
