import { useEffect, useState } from "react";
import { useGlobalState } from "../contexts/global-contexts";

interface PublishedDatesProps {
  publishedDate: string;
  updatedDate: string;
  title: string;
  description: string;
  url: string;
}

const PublishedDates = ({
  publishedDate,
  updatedDate,
  title,
  description,
  url,
}: PublishedDatesProps) => {
  const { state } = useGlobalState();
  const { language } = state;
  const [showCopied, setShowCopied] = useState(false);
  const [copiedText, setCopiedText] = useState("");

  const copyToClip = async () => {
    await navigator.clipboard.writeText(url);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  const handleShareToSocial = (socialType: string) => {
    switch (socialType) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            `${url}`
          )}&quote=${encodeURIComponent("quote")}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            url
          )}&text=${encodeURIComponent(title)}`,
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            url
          )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
            description
          )}&source=${encodeURIComponent(title)}`,
          "_blank"
        );
        break;
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${title}\n${url}`
          )}`,
          "_blank"
        );
        break;
    }
  };

  useEffect(() => {
    const changeCopiedLanguage = () => {
      switch (language) {
        case "English":
          setCopiedText("Copied to clipboard");
          break;
        case "中文":
          setCopiedText("已复制到剪贴板");
          break;
        case "Español":
          setCopiedText("Copiado al portapapeles");
          break;
      }
    };
    changeCopiedLanguage();
  }, [language]);

  //TODO change the publish date and udpate date language
  return (
    <div className="text-xs">
      <div className="font-bold text-base">Shaoyao Consulting</div>
      <div>
        Publicado el: {publishedDate} - <i>Actualizado el: {updatedDate} </i>
      </div>
      <div className="py-2 flex items-center">
        <button
          aria-label="share to whatsapp"
          className="px-2"
          onClick={() => handleShareToSocial("whatsapp")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 448 512"
            fill="#364145"
          >
            <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
          </svg>
        </button>
        <button
          aria-label="share to facebook"
          className="px-2"
          onClick={() => handleShareToSocial("facebook")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            width="10"
            viewBox="0 0 320 512"
            fill="#364145"
          >
            <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
          </svg>
        </button>
        <button
          aria-label="share to linkedin"
          className="px-2"
          onClick={() => handleShareToSocial("linkedin")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            width="14"
            viewBox="0 0 448 512"
            fill="#364145"
          >
            <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
          </svg>
        </button>
        <button
          aria-label="share to x-twitter"
          className="px-2"
          onClick={() => handleShareToSocial("twitter")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
            fill="#364145"
          >
            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
          </svg>
        </button>
        <button aria-label="share" className="px-2" onClick={copyToClip}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path d="M503.7 189.8L327.7 37.9C312.3 24.5 288 35.3 288 56v80.1C127.4 137.9 0 170.1 0 322.3c0 61.4 39.6 122.3 83.3 154.1 13.7 9.9 33.1-2.5 28.1-18.6C66.1 312.8 132.9 274.3 288 272.1V360c0 20.7 24.3 31.5 39.7 18.2l176-152c11.1-9.6 11.1-26.8 0-36.3z" />
          </svg>
        </button>
        <div
          className={`p-2 bg-shaoyao-blue text-white rounded-md transition-opacity duration-1000 ease-in-out ${
            showCopied ? "opacity-100" : "opacity-0"
          }`}
        >
          {copiedText}
        </div>
      </div>
    </div>
  );
};

export default PublishedDates;
