import IrregularBlobShape from "../irregular-blob-shape";
import { useGlobalState } from "../contexts/global-contexts";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BlogState } from "../interface/interface-type";

export const LatestPostSection = () => {
  const { state } = useGlobalState();
  const { blogData, language } = state;
  const [latestPost, setLatestPost] = useState<BlogState>();
  const [latestPostText, setLatestPostText] = useState("");

  useEffect(() => {
    if (blogData && blogData.length > 0) {
      setLatestPost(blogData[0]);
    }
  }, [blogData]);

  useEffect(() => {
    switch (language) {
      case "English":
        setLatestPostText("Latest Post");
        break;
      case "Español":
        setLatestPostText("Última publicación");
        break;
      case "中文":
        setLatestPostText("最新博客");
        break;
    }
  }, [language]);

  return (
    <div
      id="latest-post-elements"
      className="flex w-full justify-center my-8 text-white"
    >
      <div id="parent" className="grid max-w-[36.5rem] max-h-[43.5rem]">
        <div className="col-start-1 row-start-1">
          <IrregularBlobShape />
        </div>
        <div
          id="children"
          className="col-start-1 row-start-1 self-center justify-center items-center space-y-6"
        >
          <div
            id="latest-post-header"
            className="flex justify-center text-2xl font-semibold"
          >
            {latestPostText}
          </div>
          <Link
            /* Format is /blog/:id/:title-separated-by-hyphens */
            to={`/blog/${latestPost?.id}/${latestPost?.title.replaceAll(
              " ",
              "-"
            )}/`}
            className="flex justify-center items-center w-full"
            aria-label="display more latest blog posts"
          >
            <img
              id="latest-post-image"
              className="aspect-video rounded-xl object-cover"
              src={latestPost?.small_image_url}
              alt={latestPost?.header_image_alt_text}
            />
          </Link>
          <div
            id="latest-post-title"
            className="flex justify-center text-4xl font-bold"
          >
            {latestPost?.title}
          </div>
          <div id="latest-post-desc" className="text-xl">
            {latestPost?.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestPostSection;
