import { createContext, useContext, useState } from "react";
import { BlogState } from "../interface/interface-type";

interface InitialState {
  language: string;
  languageList: string[];
  servicesClicked: boolean;
  getBlogDataStatus: string;
  blogData: BlogState[] | undefined;
  hasSeenServicesElement1: boolean;
  hasSeenServicesElement2: boolean;
  hasSeenServicesElement3: boolean;
}

const initialState: InitialState = {
  language: "Español",
  languageList: ["English", "中文"],
  servicesClicked: false,
  getBlogDataStatus: "Not Loaded",
  blogData: undefined,
  hasSeenServicesElement1: false,
  hasSeenServicesElement2: false,
  hasSeenServicesElement3: false,
};

const GlobalContext = createContext({
  state: initialState,
  setState: (val: InitialState) => {},
});

export const GlobalStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [gState, setGState] = useState(initialState);
  return (
    <GlobalContext.Provider
      value={{ state: gState, setState: (val) => setGState(val) }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext(GlobalContext);
};
