import { Helmet } from "react-helmet-async";
import Header from "./header";
import Footer from "./footer";

interface PageComponentProps {
  children: React.ReactNode;
}

const PageComponent = ({ children }: PageComponentProps) => {
  return (
    <div>
      <Helmet>
        <meta name="title" property="og:title" content="Shaoyao Consulting" />
        <meta
          name="description"
          property="og:description"
          content="La firma líder en servicios profesionales de traducción de chino a español en México. ¿Necesitas traducir un documento en chino o contratar un intérprete chino español en CDMX, Monterrey, Guadalajara o el Bajío? ¡Contáctanos!"
        />
        <meta
          name="image"
          property="og:image"
          content="https://shaoyaoconsulting.com/static/media/1-2048.7a904c99a26e0981d7f1.webp"
        />
        <meta property="og:url" content="https://shaoyaoconsulting.com/" />
        <meta name="twitter:card" content="Shaoyao Consulting" />
        <meta
          property="twitter:domain"
          content="https://shaoyaoconsulting.com/"
        />
        <meta property="twitter:url" content="https://shaoyaoconsulting.com/" />
        <meta name="twitter:title" content="Shaoyao Consulting" />
        <meta
          name="twitter:description"
          content="La firma líder en servicios profesionales de traducción de chino a español en México. ¿Necesitas traducir un documento en chino o contratar un intérprete chino español en CDMX, Monterrey, Guadalajara o el Bajío? ¡Contáctanos!"
        />
        <meta
          name="twitter:image"
          content="https://shaoyaoconsulting.com/static/media/1-2048.7a904c99a26e0981d7f1.webp"
        />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default PageComponent;
