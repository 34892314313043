import LatestPostSection from "./blog/latest-post-section";
import FeaturedPostsSection from "./blog/featured-posts-section";
import { useEffect, useState } from "react";
import PageComponent from "./page-component";
import { getMorePosts } from "./utils/fetch-blog-data";
import { useGlobalState } from "./contexts/global-contexts";

export const Blog = () => {
  const { state, setState } = useGlobalState();
  const { language, blogData } = state;
  const [displayShowMoreButton, setDisplayShowMoreButton] = useState(true);
  const [showMoreText, setShowMoreText] = useState("");

  // TODO: Add a slide-down animation as new elements are revealed
  const handleGetMorePosts = async () => {
    let loadedBlogIds: Set<number> = new Set();
    blogData?.forEach((elem) => loadedBlogIds.add(elem.id));

    const morePosts = await getMorePosts(loadedBlogIds);
    if (morePosts && morePosts.length > 0) {
      const newMorePosts = blogData?.concat(morePosts);
      setState({ ...state, blogData: newMorePosts });
    } else setDisplayShowMoreButton(false);
  };

  useEffect(() => {
    switch (language) {
      case "English":
        setShowMoreText("Show More");
        break;
      case "中文":
        setShowMoreText("展示更多");
        break;
      case "Español":
        setShowMoreText("Mostrar más");
        break;
    }
  }, [language]);

  return (
    <PageComponent>
      <div className="bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] w-full h-full flex flex-col font-open-sans text-white p-6 text-center">
        <div className="flex flex-col justify-center text-charcoal bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] py-2">
          <LatestPostSection />
          <FeaturedPostsSection />
          <div className="flex justify-center p-8">
            {displayShowMoreButton && (
              <div
                id="show-more-button-container"
                className="flex flex-col items-center"
              >
                <button
                  aria-label="show more blog posts"
                  onClick={() => handleGetMorePosts()}
                  id="show-more-button"
                  className="w-[9.375rem] h-[3.125rem] bg-shaoyao-light-green text-white text-xl font-extrabold rounded-[1.25rem] font-OpenSans"
                >
                  {showMoreText}
                </button>
                <div
                  id="button-middle-line"
                  className="w-24 h-[0.3rem] bg-shaoyao-light-green m-1"
                />
                <div
                  id="button-last-line"
                  className="w-12 h-[0.3rem] bg-shaoyao-light-green"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageComponent>
  );
};

export default Blog;
