import PageComponent from "./page-component";

const NotFound = () => {
  return (
    <PageComponent>
      <div className="h-screen flex flex-col justify-center items-center bg-gradient-to-br from-shaoyao-blue to-shaoyao-green font-Erode text-white">
        <div className="text-5xl p-2">404</div>
        <div className="text-4xl">Page Not Found</div>
      </div>
    </PageComponent>
  );
};

export default NotFound;
