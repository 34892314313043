export const IrregularBlobShape = () => {
  return (
    <svg
      width="100%" // was 584, 100% makes sizing responsive
      height="100%" // was 697, 100% makes sizing responsive
      viewBox="0 0 584 697"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="309.175"
        cy="341.426"
        rx="274.825"
        ry="263.342"
        fill="#004C72"
      />
      <ellipse
        cx="309.175"
        cy="341.426"
        rx="274.825"
        ry="263.342"
        fill="#006454"
      />
      <ellipse
        cx="309.175"
        cy="341.426"
        rx="274.825"
        ry="263.342"
        fill="#008F62"
        fillOpacity="0.3"
      />
      <ellipse
        cx="193.581"
        cy="116.36"
        rx="105.643"
        ry="116.36"
        fill="#004C72"
      />
      <ellipse
        cx="193.581"
        cy="116.36"
        rx="105.643"
        ry="116.36"
        fill="#006454"
      />
      <ellipse
        cx="193.581"
        cy="116.36"
        rx="105.643"
        ry="116.36"
        fill="#008F62"
        fillOpacity="0.3"
      />
      <ellipse
        cx="224.967"
        cy="565.727"
        rx="224.3"
        ry="130.906"
        fill="#004C72"
      />
      <ellipse
        cx="224.967"
        cy="565.727"
        rx="224.3"
        ry="130.906"
        fill="#006454"
      />
      <ellipse
        cx="224.967"
        cy="565.727"
        rx="224.3"
        ry="130.906"
        fill="#008F62"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default IrregularBlobShape;
