import { useEffect, useState } from "react";
import PageComponent from "../page-component";
import { useGlobalState } from "../contexts/global-contexts";

const Cancellation = () => {
  const { state } = useGlobalState();
  const { language } = state;
  const [cancellationText, setCancellationText] = useState({
    cancellation: { title: "", description: "" },
  });

  useEffect(() => {
    switch (language) {
      case "English":
        setCancellationText({
          cancellation: {
            title: "CANCELLATION POLICY",
            description: `The services offered by Shaoyao Consulting are provided by a select group of professionals with limited availability. For this reason, we inform our clients that any cancellation made for reasons not attributable to Shaoyao Consulting will incur a penalty of at least 50% of the total quoted amount. The cancellation must be made in writing at least five business days before the agreed event or delivery date. If the cancellation is made with less than five business days' notice, the total amount of the service will be charged.

Date or time changes notified to Shaoyao Consulting after service confirmation will be subject to consideration and may be accepted or rejected depending on the availability of the professionals involved. If the changes are not accepted, the service will be provided on the originally reserved dates and times. The client must accept the original dates or proceed with the cancellation of the service in accordance with the conditions mentioned above.

In case of cancellation of any service for reasons not attributable to Shaoyao Consulting, the corresponding penalties and charges will apply as established in this policy. Any credit balance in favor of the client may be used exclusively for the contracting of future services; therefore, no payment made to Shaoyao Consulting will be refundable.

If the cancellation of any service is for reasons attributable to Shaoyao Consulting, and once the client accepts the cancellation of the corresponding digital tax receipt (CFDI), Shaoyao Consulting will refund the total amount transferred to the client, without incurring any other penalty and remaining free of any additional liability towards the client.

The client cannot personally choose the interpreters, translators, instructors, consultants, or other professionals assigned to their project, as this decision is the exclusive right of Shaoyao Consulting. Therefore, the identity of the person or persons who execute the accepted service cannot be considered at any time and under any circumstance a valid reason to cancel without penalty; the responsibility for canceling for this reason falls exclusively on the client.

By accepting any of the services provided by Shaoyao Consulting, you acknowledge and accept what is expressed in this cancellation policy and commit to comply with its conditions.",
        `,
          },
        });
        break;
      case "中文":
        setCancellationText({
          cancellation: {
            title: "取消政策",
            description: `Shaoyao Consulting 提供的服务由一组时间有限的专业人员完成。因此，我们通知客户，任何非因 Shaoyao Consulting 原因而取消的服务，都将被收取至少报价总额 50% 的违约金。取消必须在活动开始日期或交付日期前至少五个工作日以书面形式提出。如果取消是在少于五个工作日的通知期内提出，将收取服务的全额费用。

在服务确认后通知 Shaoyao Consulting 的日期或时间变更，将视情况而定，可能会根据相关专业人员的时间被接受或拒绝。如果变更未被接受，服务将按照最初预订的日期和时间提供。客户必须接受原定日期，或根据上述条件继续取消服务。

如因非 Shaoyao Consulting 原因取消任何服务，将按照本政策规定收取相应的违约金和费用。客户的任何余额仅可用于未来服务的合同，因此，向 Shaoyao Consulting 支付的任何款项均不予退还。

如果因 Shaoyao Consulting 的原因取消任何服务，在客户接受相应的电子税务发票（CFDI）取消后，Shaoyao Consulting 将退还客户已转账的全部金额，不会产生任何其他违约金，并且对客户不承担任何额外责任。

客户不能自行选择分配到其项目的口译员、笔译员、讲师、顾问或其他专业人员，此决定权为 Shaoyao Consulting 的专属权利。因此，执行已接受服务的人员身份，在任何时间和任何情况下，都不能被视为无违约金取消的正当理由；因该原因取消的责任完全由客户承担。

通过接受 Shaoyao Consulting 提供的任何服务，您确认并接受本取消政策中所述的内容，并承诺遵守其条件。`,
          },
        });
        break;
      case "Español":
        setCancellationText({
          cancellation: {
            title: "POLÍTICA DE CANCELACIONES",
            description: `Los servicios ofrecidos por Shaoyao Consulting son proporcionados por un selecto grupo de profesionales con disponibilidad limitada. Por este motivo, informamos a nuestros clientes que cualquier cancelación realizada por causas no imputables a Shaoyao Consulting generará una penalización de al menos el 50% del monto total cotizado. La cancelación deberá realizarse por escrito al menos cinco días hábiles antes del evento o fecha de entrega acordada. Si la cancelación se realiza con menos de cinco días hábiles de anticipación, se cobrará el monto total del servicio.

Los cambios de fecha u horario notificados a Shaoyao Consulting después de la confirmación de un servicio estarán sujetos a consideración y podrán ser aceptados o rechazados según la disponibilidad de los profesionales involucrados. Si los cambios no son aceptados, el servicio se brindará en las fechas y horarios originalmente reservados. El cliente deberá aceptar las fechas originales o proceder con la cancelación del servicio conforme a las condiciones mencionadas anteriormente.

En caso de cancelación de cualquier servicio por causas no imputables a Shaoyao Consulting, se aplicarán las penalizaciones y cargos correspondientes conforme a lo establecido en esta política. Cualquier saldo a favor del cliente podrá ser utilizado exclusivamente para la contratación de futuros servicios; por lo tanto, ningún pago realizado a Shaoyao Consulting será reembolsable.

Si la cancelación de cualquier servicio es por causas imputables a Shaoyao Consulting, y una vez que el cliente acepte la cancelación del CFDI correspondiente, Shaoyao Consulting devolverá al cliente la totalidad del monto transferido, sin incurrir en ninguna otra penalización y quedando libre de toda responsabilidad adicional hacia el cliente.

El cliente no podrá elegir personalmente a los intérpretes, traductores, instructores, consultores u otros profesionales asignados a su proyecto, siendo esta decisión derecho exclusivo de Shaoyao Consulting. Por lo tanto, la identidad de la persona o personas que ejecuten el servicio aceptado no podrá considerarse en ningún momento y bajo ninguna circunstancia una razón válida para cancelar sin penalización; la responsabilidad de cancelar por este motivo recaerá exclusivamente en el cliente.

Al aceptar cualquiera de los servicios brindados por Shaoyao Consulting, usted reconoce y acepta lo expresado en esta política de cancelaciones y se compromete a cumplir con sus condiciones.`,
          },
        });
        break;
    }
  }, [language]);

  return (
    <PageComponent>
      <div className="flex justify-center text-charcoal bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] py-2 shadow-lg whitespace-pre-line">
        <div className="md:w-4/5 p-6 md:p-10 md:px-28 bg-white">
          <h1 className="flex justify-center font-bold text-lg">
            {cancellationText.cancellation.title}
          </h1>
          <br />
          <p>{cancellationText.cancellation.description}</p>
          <br />
        </div>
      </div>
    </PageComponent>
  );
};

export default Cancellation;
