import { useEffect, useState } from "react";
import CallToAction from "../callToAction";
import { useGlobalState } from "../contexts/global-contexts";

const FooterCallToAction = () => {
  const { state } = useGlobalState();
  const { language } = state;
  const [footerBoxText, setFooterBoxText] = useState({ quote: "", button: "" });
  const [showCallToAction, setShowCallToAction] = useState(false);

  const handleShowCallToAction = () => {
    setShowCallToAction(true);
    setTimeout(() => setShowCallToAction(false), 20000);
  };

  useEffect(() => {
    switch (language) {
      case "English":
        setFooterBoxText({
          quote: "Contact Us For a Customized Proposal!",
          button: "Get Free Quote",
        });
        break;
      case "中文":
        setFooterBoxText({
          quote: "请联系我们，获取定制报价！",
          button: "立即询价",
        });
        break;
      case "Español":
        setFooterBoxText({
          quote: "¡Contáctanos para recibir una propuesta a tu medida!",
          button: "Pedir cotización",
        });
        break;
    }
  }, [language]);

  return (
    <div className="w-full flex justify-center text-center px-8 lg:pb-4">
      <div className="hidden lg:flex w-1/3" />
      <div className="w-full lg:w-1/3 rounded-3xl p-4 space-y-6">
        <div className="w-full p-2 pb-4 font-bold text-xl">
          {footerBoxText.quote}
        </div>
        {!showCallToAction && (
          <button
            aria-label="displays links to whatsapp and email"
            id="cta_quote_footer"
            className="p-2 px-4 bg-shaoyao-red rounded-lg text-2xl font-bold shadow-2xl"
            onClick={handleShowCallToAction}
          >
            {footerBoxText.button}
          </button>
        )}
        {showCallToAction && <CallToAction />}
      </div>
      <div className="hidden lg:flex w-1/3" />
    </div>
  );
};

export default FooterCallToAction;
