import PageComponent from "../page-component";
import PublishedDates from "./published-dates";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useGlobalState } from "../contexts/global-contexts";
import { BlogState } from "../interface/interface-type";
import NotFound from "../../Components/notFound";
import { getSingleBlogPost } from "../utils/fetch-blog-data";

const SingleBlogPost = () => {
  const [currentBlogPost, setCurrentBlogPost] = useState<BlogState>();
  const { state, setState } = useGlobalState();
  const { getBlogDataStatus, blogData } = state;
  const { id } = useParams();

  useEffect(() => {
    if (blogData) {
      const currentBlog = blogData.find((data) => data.id === Number(id));
      if (currentBlog) setCurrentBlogPost(currentBlog);
      else {
        const getNewSingleBlogPost = async () => {
          const newBlogData = await getSingleBlogPost(Number(id));
          if (newBlogData) {
            const newUpdatedBlogData = [...blogData];
            newUpdatedBlogData.push(newBlogData);
            setCurrentBlogPost(newBlogData);
            setState({ ...state, blogData: newUpdatedBlogData });
          }
        };
        getNewSingleBlogPost();
      }
    }
  }, [blogData]);

  //TODO add the other 2 languages for loading and failed
  if (getBlogDataStatus === "Loading" || getBlogDataStatus === "Not Loaded")
    return (
      <PageComponent>
        <h1 className="h-screen flex items-center justify-center p-20">
          Loading...
        </h1>
      </PageComponent>
    );
  if (getBlogDataStatus === "Failed")
    return (
      <PageComponent>
        <h1 className="h-screen flex items-center justify-center p-20">
          Sorry, blog not found.
        </h1>
      </PageComponent>
    );
  if (!currentBlogPost) {
    return <NotFound />;
  }
  return (
    <PageComponent>
      <Helmet>
        <meta
          name="title"
          property="og:title"
          content={currentBlogPost?.title}
        />
        <meta
          name="description"
          property="og:description"
          content={currentBlogPost?.description}
        />
        <meta
          name="image"
          property="og:image"
          content={currentBlogPost.small_image_url}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content={currentBlogPost?.title} />
        <meta property="twitter:domain" content={window.location.href} />
        <meta property="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content="Shaoyao Consulting" />
        <meta
          name="twitter:description"
          content={currentBlogPost?.description}
        />
        <meta name="twitter:image" content={currentBlogPost.small_image_url} />
      </Helmet>
      <div className="flex justify-center text-charcoal bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] py-2 shadow-lg">
        <div className="md:w-4/5 p-6 md:p-10 md:px-28 bg-white">
          <h1 className="font-extrabold text-xl">{currentBlogPost?.title}</h1>
          <br />
          <div className="text-lg">{currentBlogPost?.description}</div>
          <br />
          <div className="max-w-[1000px] flex flex-col">
            {currentBlogPost && (
              <img
                loading="lazy"
                className="w-100"
                srcSet={`${currentBlogPost.small_image_url} 500w,
            ${currentBlogPost.small_image_url} 750w,
            ${currentBlogPost.large_image_url} 1000w`}
                sizes="(min-width: 1600px) 1000px, (min-width: 780px) calc(75vw - 185px), calc(100vw - 48px)"
                src={currentBlogPost.large_image_url}
                alt={currentBlogPost?.header_image_alt_text}
              />
            )}
            <br />
            <div>
              <i>{currentBlogPost?.tagline}</i>
            </div>
          </div>
          <br />
          <PublishedDates
            publishedDate={currentBlogPost?.publishedAt}
            updatedDate={currentBlogPost?.updatedAt}
            title={currentBlogPost?.title!}
            description={currentBlogPost?.description!}
            url={window.location.href}
          />
          <br />
          <div
            dangerouslySetInnerHTML={{ __html: currentBlogPost?.content_body! }}
          />
        </div>
      </div>
    </PageComponent>
  );
};

export default SingleBlogPost;
