import { BlogState } from "../interface/interface-type";

interface InitialBlogData {
  id: number;
  attributes: {
    Title: string;
    Description: string;
    Tagline: string;
    Content_body: string;
    Header_image: {
      data: {
        attributes: {
          formats: {
            small: {
              url: string;
            };
            medium: {
              url: string;
            };
            large: {
              url: string;
            };
          };
        };
      };
    };
    Header_image_alt_text: string;
    publishedAt: string;
    updatedAt: string;
    Featured_post_1: boolean;
    Featured_post_2: boolean;
    Featured_post_3: boolean;
  };
}

const apiUrl = process.env.REACT_APP_STRAPI_API_URL;
const apiKey = process.env.REACT_APP_STRAPI_API_TOKEN;
const endpointBase =
  "api/posts?fields[0]=Title&fields[1]=Description&fields[2]=Tagline&fields[3]=Content_body&fields[4]=Header_image_alt_text&fields[5]=publishedAt&fields[6]=updatedAt&fields[7]=Featured_post_1&fields[8]=Featured_post_2&fields[9]=Featured_post_3&populate=*";
const featuredPostFilter =
  "filters[$or][0][Featured_post_1][$eq]=true&filters[$or][1][Featured_post_2][$eq]=true&filters[$or][2][Featured_post_3][$eq]=true";
const latestPostFilter = "sort=id:desc&pagination[limit]=1";
const imageBaseUrl = `https://${apiUrl}`;

export const fetchBlogData = async (urlString: string) => {
  try {
    const getBlogDataResponse = await fetch(urlString, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    if (!getBlogDataResponse.ok) {
      throw new Error("Didn't get response from network");
    }
    const blogData = await getBlogDataResponse.json();
    if (blogData) {
      const newBlogData = blogData.data.map(
        (data: InitialBlogData): BlogState => {
          const {
            Title,
            Description,
            Tagline,
            Content_body,
            Header_image,
            Header_image_alt_text,
            publishedAt,
            updatedAt,
            Featured_post_1,
            Featured_post_2,
            Featured_post_3,
          } = data.attributes;
          return {
            id: data.id,
            title: Title,
            description: Description,
            tagline: Tagline,
            content_body: Content_body,
            header_image_data: Header_image,
            header_image_alt_text: Header_image_alt_text,
            small_image_url:
              imageBaseUrl + Header_image.data.attributes.formats.small.url,
            medium_image_url:
              imageBaseUrl + Header_image.data.attributes.formats.medium.url,
            large_image_url:
              imageBaseUrl + Header_image.data.attributes.formats.large.url,
            publishedAt: new Date(publishedAt).toLocaleDateString("en-GB"),
            updatedAt: new Date(updatedAt).toLocaleDateString("en-GB"),
            featured_post_1: Featured_post_1,
            featured_post_2: Featured_post_2,
            featured_post_3: Featured_post_3,
          };
        }
      );
      return newBlogData;
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadInitialBlogData = async () => {
  const latestPost = await fetchBlogData(
    `https://${apiUrl}/${endpointBase}&${latestPostFilter}`
  );
  const featuredPosts = await fetchBlogData(
    `https://${apiUrl}/${endpointBase}&${featuredPostFilter}`
  );

  if (latestPost && featuredPosts) {
    return [...latestPost, ...featuredPosts];
  } else {
    return null;
  }
};

export const getSingleBlogPost = async (id: number) => {
  try {
    const singleBlogPost = await fetch(
      `https://${apiUrl}/api/posts/${id}?fields[0]=Title&fields[1]=Description&fields[2]=Tagline&fields[3]=Content_body&fields[4]=Header_image_alt_text&fields[5]=publishedAt&fields[6]=updatedAt&fields[7]=Featured_post_1&fields[8]=Featured_post_2&fields[9]=Featured_post_3&populate=*`,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    if (!singleBlogPost.ok) {
      throw new Error("Didn't get response from network");
    }
    const newBlogData = await singleBlogPost.json();
    if (newBlogData) {
      const newSingleBlogPost = newBlogData.data;
      const {
        Title,
        Description,
        Tagline,
        Content_body,
        Header_image,
        Header_image_alt_text,
        publishedAt,
        updatedAt,
        Featured_post_1,
        Featured_post_2,
        Featured_post_3,
      } = newSingleBlogPost.attributes;
      return {
        id: id,
        title: Title,
        description: Description,
        tagline: Tagline,
        content_body: Content_body,
        header_image_data: Header_image,
        header_image_alt_text: Header_image_alt_text,
        small_image_url:
          imageBaseUrl + Header_image.data.attributes.formats.small.url,
        medium_image_url:
          imageBaseUrl + Header_image.data.attributes.formats.medium.url,
        large_image_url:
          imageBaseUrl + Header_image.data.attributes.formats.large.url,
        publishedAt: new Date(publishedAt).toLocaleDateString("en-GB"),
        updatedAt: new Date(updatedAt).toLocaleDateString("en-GB"),
        featured_post_1: Featured_post_1,
        featured_post_2: Featured_post_2,
        featured_post_3: Featured_post_3,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getMorePosts = async (loadedBlogIds: Set<number>) => {
  try {
    const morePosts = await fetch(
      `https://${apiUrl}/api/posts?filters[id][$notIn]=${Array.from(
        loadedBlogIds
      )}&fields[0]=Title&fields[1]=Description&fields[2]=Tagline&fields[3]=Content_body&fields[4]=Header_image_alt_text&fields[5]=publishedAt&fields[6]=updatedAt&populate=*&sort=id:desc&pagination[limit]=10`,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    const newMorePosts = await morePosts.json();
    if (newMorePosts) {
      const newMoreBlogData = newMorePosts.data.map((data: InitialBlogData) => {
        const {
          Title,
          Description,
          Tagline,
          Content_body,
          Header_image,
          Header_image_alt_text,
          publishedAt,
          updatedAt,
        } = data.attributes;
        return {
          id: data.id,
          title: Title,
          description: Description,
          tagline: Tagline,
          content_body: Content_body,
          header_image_data: Header_image,
          header_image_alt_text: Header_image_alt_text,
          small_image_url:
            imageBaseUrl + Header_image.data.attributes.formats.small.url,
          medium_image_url:
            imageBaseUrl + Header_image.data.attributes.formats.medium.url,
          large_image_url:
            imageBaseUrl + Header_image.data.attributes.formats.large.url,
          publishedAt: new Date(publishedAt).toLocaleDateString("en-GB"),
          updatedAt: new Date(updatedAt).toLocaleDateString("en-GB"),
        };
      });
      return newMoreBlogData;
    }
  } catch (error) {
    console.error(error);
  }
};
