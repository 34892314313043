import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { InitializeGoogleAnalytics } from "./Components/google-analytics-tracker";
import { HelmetProvider } from "react-helmet-async";
import Landing from "./Components/landing/landing";
import Blog from "./Components/blog";
import NotFound from "./Components/notFound";
import { GlobalStateProvider } from "./Components/contexts/global-contexts";
import SingleBlogPost from "./Components/blog/single-blog-post";
import { Cancellation, Privacy } from "./Components/policy";

InitializeGoogleAnalytics();

const App = () => {
  return (
    <div className="font-OpenSans">
      <HelmetProvider>
        <GlobalStateProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id/:title" element={<SingleBlogPost />} />
              <Route path="/cancellation" element={<Cancellation />} />
              <Route path="/cancelaciones" element={<Cancellation />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </GlobalStateProvider>
      </HelmetProvider>
    </div>
  );
};

export default App;
