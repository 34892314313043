import PageComponent from "../page-component";
import { useEffect, useState } from "react";
import { useGlobalState } from "../contexts/global-contexts";

const Privacy = () => {
  const { state } = useGlobalState();
  const { language } = state;
  const [privacyText, setPrivacyText] = useState({
    privacy: { title: "", description: "" },
    responsiblility: { title: "", description: "" },
    peronal: {
      title: "",
      description: "",
      a: "",
      b: "",
      c: "",
      d: "",
      e: "",
      f: "",
    },
    cookies: { title: "", description: "" },
    security: { title: "", description: "" },
    purpose: { title: "", description: "", a: "", b: "", c: "" },
    sharing: { title: "", description: "" },
    arco: { title: "", description: "" },
    revocation: { title: "", description: "" },
    changes: { title: "", description: "" },
    update: "",
  });

  useEffect(() => {
    switch (language) {
      case "English":
        setPrivacyText({
          privacy: {
            title: "PRIVACY NOTICE",
            description:
              "In compliance with the provisions of the Federal Law for the Protection of Personal Data in Possession of Individuals, SHAOYAO CONSULTING SAS DE CV makes this Privacy Notice available to you.",
          },
          responsiblility: {
            title: "RESPONSIBLE FOR THE PROTECTION OF PERSONAL DATA",
            description:
              "SHAOYAO CONSULTING SAS DE CV is the person responsible for the use and protection of your personal data, and in this regard we inform you of the following:",
          },

          peronal: {
            title: "PERSONAL DATA USED",
            description:
              "To carry out the purposes described in this Privacy Notice, we will use the following personal data:",
            a: "Contact data",
            b: "Identification data",
            c: "Billing data",
            d: "Employment data",
            e: "Academic data",
            f: "Financial or patrimonial data",
          },
          cookies: {
            title: "COOKIES, WEB BEACONS AND SIMILAR TECHNOLOGIES",
            description:
              "We use cookies, web beacons and/or similar technologies to enhance your experience on our website. These tools collect information such as your IP address, browser type, pages visited and the duration of your visit. This information may be used to analyze trends, administer the site and improve our services. You can disable these technologies in your browser settings, but please note that this may affect the functionality of the site.",
          },
          security: {
            title: "SECURITY MEASURES AND RETENTION TIME",
            description:
              "We have implemented technical, administrative and physical security measures to safeguard the security of your personal data, in compliance with legal standards.\n\nThe retention of your data will be extended for a period of 12 months, in line with the purposes stated in this notice and the corresponding legal deadlines.",
          },
          purpose: {
            title: "PURPOSES OF THE USE OF PERSONAL DATA",
            description:
              "We will use the data collected for the following purposes that are necessary for the requested service:",
            a: "Contact purpose",
            b: "Billing and collection purposes",
            c: "Purpose of establishing a legal relationship",
          },
          sharing: {
            title: "SHARING PERSONAL INFORMATION AND PURPOSES",
            description:
              "We will not share your personal data with third parties, except for legal requirements. The data will be used exclusively for the purposes indicated in this Privacy Notice.",
          },
          arco: {
            title: "ARCO RIGHTS AND CONTACT",
            description:
              "You have the right to know the personal data we have about you, as well as their use and the conditions under which they are treated (Right of Access). Likewise, you have the right to request the correction of your personal information if it is outdated, inaccurate or incomplete (Right of Rectification); to request the deletion of your data from our records or databases if you consider that its use is not in accordance with the principles, duties and obligations established by law (Right of Cancellation); as well as to oppose the processing of your personal data for specific purposes (Right of Opposition). These rights are known as ARCO rights.\n\nBelow, we provide the contact details of the person or department in charge of managing requests related to ARCO rights:\n\nName: Shaoyao Consulting SAS DE CV\nTelephone: 5565409870\nE-mail: info@shaoyaoconsulting.com\n\nTo exercise any of the ARCO rights, it is necessary to submit the respective request through the following email:\n\ninfo@shaoyaoconsulting.com\n\nThe response to your request will be communicated as follows:\n\nResponses will be sent by email to the address provided by the applicant.\n\nThe deadline to provide a response to your request will be as follows: 20 working days.",
          },
          revocation: {
            title: "REVOCATION OF CONSENT AND LIMITATION",
            description:
              "If you wish to revoke the consent granted for the processing of your personal data or limit its disclosure, you can submit the respective request through the following email:\ninfo@shaoyaoconsulting.com\n\nThe communication of the response to the request for revocation or limitation of disclosure of your data will be carried out as follows:\nResponses will be sent by e-mail to the address provided by the applicant.\n\nThe response to the request for revocation or limitation of disclosure of your data will be provided within a maximum period of: 20 working days.",
          },
          changes: {
            title: "CHANGES TO THE PRIVACY NOTICE",
            description:
              "This privacy notice may undergo modifications, changes or updates derived from new legal requirements; from our own needs for the products or services we offer; from our privacy practices; from changes in our business model, or for other reasons, therefore, we are committed to keep you informed about the changes that this privacy notice may undergo, however, you can request information about whether it has undergone any change through the following email:",
          },
          update: "Last update: December 11, 2023",
        });
        break;
      case "中文":
        setPrivacyText({
          privacy: {
            title: "隐私声明",
            description:
              "根据《联邦私人持有的个人数据保护法》的规定，SHAOYAO CONSULTING SAS DE CV 向您提供本隐私声明。",
          },
          responsiblility: {
            title: "个人数据保护负责人",
            description:
              "SHAOYAO CONSULTING SAS DE CV是使用和保护您的个人数据的责任人，在这方面，我们向您通知如下：",
          },
          peronal: {
            title: "使用的个人数据",
            description: "为实现本隐私声明所述目的，我们将使用以下个人数据：",
            a: "联系方式",
            b: "身份数据",
            c: "账单数据",
            d: "就业数据",
            e: "学术数据",
            f: "个人或财务数据",
          },
          cookies: {
            title: "Cookies、网络信标和类似技术",
            description:
              "我们使用 Cookie、网络信标和/或类似技术来提升您的网站体验。这些工具收集的信息包括您的 IP地址、浏览器类型、访问过的页面和访问时长。这些信息可用于分析趋势、管理网站和改进我们的服务。您可以在浏览器设置中禁用这些技术，但请注意这可能会影响网站的功能。",
          },
          security: {
            title: "安全措施和保留时间",
            description:
              "我们已采取技术、管理和物理安全措施，按照法律标准保护您的个人数据安全。\n\n您的数据保留期限为 12 个月，与本通知中规定的目的和相关法律期限一致。",
          },
          purpose: {
            title: "个人数据的使用目的",
            description:
              "我们将把收集到的数据用于以下目的，这些目的对于所要求的服务是必要的：",
            a: "联系目的",
            b: "账单和收款目的",
            c: "建立法律关系的目的",
          },
          sharing: {
            title: "共享个人信息和目的",
            description:
              "除法律要求外，我们不会与第三方共享您的个人信息。这些数据将仅用于本隐私声明所述目的。",
          },
          arco: {
            title: "隐私权和联系方式",
            description:
              "您有权知道我们持有您的哪些个人数据、如何使用这些数据以及处理这些数据的条件（访问权）。同样，如果您的个人信息过时、不准确或不完整，您有权要求更正（更正权）；如果您认为您的数据使用不符合法律规定的原则、责任和义务，您有权要求从我们的记录或数据库中删除您的数据（注销权）；以及反对出于特定目的处理您的个人数据（反对权）。这些权利被称为 ARCO 权利。\n\n以下是负责管理 ARCO 权利相关请求的人员或部门的详细联系方式：\n\n名字： Shaoyao Consulting SAS DE CV\n电话：5565409870 \n电子邮件：info@shaoyaoconsulting.com\n\n要行使 ARCO 的任何权利，必须通过以下电子邮件提交相关申请：\n\ninfo@shaoyaoconsulting.com\n\n您的请求将以下列方式得到回复：\n\n答复将通过电子邮件发送至申请人提供的地址。\n\n答复期限为 20 个工作日。",
          },
          revocation: {
            title: "撤销同意和限制",
            description:
              "如果您希望撤销对您个人数据处理的同意或限制其披露，您可以通过以下电子邮件提交相关请求：\ninfo@shaoyaoconsulting.com\n\n对于撤销或限制披露您的数据的请求，将按以下方式进行回复：\n回复将通过电子邮件发送至申请人提供的地址。\n\n对撤销或限制披露您的数据申请的回复将在最长 20 个工作日内提供。",
          },
          changes: {
            title: "隐私声明的变更",
            description:
              "本隐私声明可能会由于新的法律要求、我们自身对所提供产品或服务的需求、我们的隐私保护惯例、我们业务模式的变化或其他原因而进行修改、变更或更新，因此，我们承诺随时向您通报本隐私声明可能发生的变更，但是，您可以通过以下电子邮件请求了解本隐私声明是否发生了任何变更：",
          },
          update: "最后更新日期：2023 年 12 月 11 日",
        });
        break;
      case "Español":
        setPrivacyText({
          privacy: {
            title: "AVISO DE PRIVACIDAD",
            description:
              "En cumplimiento con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de Particulares, SHAOYAO CONSULTING SAS DE CV pone a su disposición el presente Aviso de Privacidad.",
          },
          responsiblility: {
            title: "RESPONSABLE DE LA PROTECCIÓN DE DATOS PERSONALES",
            description:
              "SHAOYAO CONSULTING SAS DE CV es la persona responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:",
          },
          peronal: {
            title: "DATOS PERSONALES UTILIZADOS",
            description:
              "Para llevar a cabo las finalidades descritas en este Aviso de Privacidad, haremos uso de los siguientes datos personales:",
            a: "Datos de contacto",
            b: "Datos de identificación",
            c: "Datos de facturación",
            d: "Datos laborales",
            e: "Datos académicos",
            f: "Datos patrimoniales o financieros",
          },
          cookies: {
            title: "COOKIES, WEB BEACONS Y TECNOLOGÍAS SIMILARES",
            description:
              "Utilizamos cookies, web beacons y/o tecnologías similares para mejorar su experiencia en nuestro sitio web. Estas herramientas recopilan información como su dirección IP, tipo de navegador, páginas visitadas y la duración de su visita. Esta información se puede utilizar para analizar tendencias, administrar el sitio y mejorar nuestros servicios. Puede deshabilitar estas tecnologías en la configuración de su navegador, pero tenga en cuenta que esto podría afectar la funcionalidad del sitio.",
          },
          security: {
            title: "MEDIDAS DE SEGURIDAD Y TIEMPO DE CONSERVACIÓN",
            description:
              "Hemos implementado medidas de seguridad técnicas, administrativas y físicas para resguardar la seguridad de sus datos personales, cumpliendo con los estándares legales.\n\nLa retención de sus datos se extenderá por un periodo de 12 meses, en consonancia con los propósitos expuestos en este aviso y los plazos legales correspondientes.",
          },
          purpose: {
            title: "FINALIDADES DEL USO DE DATOS PERSONALES",
            description:
              "Utilizaremos los datos recabados para las siguientes finalidades que son necesarias para el servicio solicitado:",
            a: "Finalidad de contacto",
            b: "Finalidad de facturación y cobro",
            c: "Finalidad de establecer una relación jurídica",
          },
          sharing: {
            title: "COMPARTIR INFORMACIÓN PERSONAL Y FINES",
            description:
              "No compartiremos sus datos personales con terceros, salvo por requerimientos legales. Los datos serán utilizados exclusivamente para los fines señalados en este Aviso de Privacidad.",
          },
          arco: {
            title: "DERECHOS ARCO Y CONTACTO",
            description:
              "Usted tiene el derecho de conocer los datos personales que tenemos sobre usted, así como su utilización y las condiciones bajo las cuales son tratados (Derecho de Acceso). De igual manera, le asiste el derecho de solicitar la corrección de su información personal en caso de estar desactualizada, inexacta o incompleta (Derecho de Rectificación); requerir la eliminación de sus datos de nuestros registros o bases de datos si considera que su uso no concuerda con los principios, deberes y obligaciones establecidos por la normativa (Derecho de Cancelación); así como oponerse al tratamiento de sus datos personales para fines específicos (Derecho de Oposición). Estos derechos son conocidos como derechos ARCO.\n\nA continuación, proporcionamos los datos de contacto de la persona o departamento encargado de gestionar las solicitudes relacionadas con los derechos ARCO:\n\nNombre: Shaoyao Consulting\nTeléfono: 5565409870 \nCorreo electrónico: info@shaoyaoconsulting.com\n\nPara ejercer cualquier de los derechos ARCO, es necesario presentar la solicitud respectiva mediante el siguiente correo electrónico:\n\ninfo@shaoyaoconsulting.com\n\nLa respuesta a su solicitud será comunicada de la siguiente manera:\n\nLas respuestas serán enviadas por correo electrónico a la dirección proporcionada por el solicitante.\n\nEl plazo para brindar respuesta a su solicitud será el siguiente: 20 días hábiles",
          },
          revocation: {
            title: "REVOCACIÓN DE CONSENTIMIENTO Y LIMITACIÓN",
            description:
              "Si desea revocar el consentimiento otorgado para el tratamiento de sus datos personales o limitar su divulgación, puede presentar la solicitud respectiva a través del siguiente correo electrónico:\ninfo@shaoyaoconsulting.com\n\nLa comunicación de la respuesta a la solicitud de revocación o limitación de divulgación de sus datos se llevará a cabo de la siguiente forma:\nLas respuestas serán enviadas por correo electrónico a la dirección proporcionada por el solicitante.\n\nLa respuesta a la solicitud de revocación o limitación de divulgación de sus datos se proporcionará en un plazo máximo de: 20 días hábiles",
          },
          changes: {
            title: "CAMBIOS AL AVISO DE PRIVACIDAD",
            description:
              "El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas, por lo cual, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, sin embargo, usted puede solicitar información sobre si el mismo ha sufrido algún cambio a través del siguiente correo electrónico:",
          },
          update: "Última actualización: 11 de diciembre de 2023",
        });
        break;
    }
  }, [language]);

  return (
    <PageComponent>
      <div className="flex justify-center text-charcoal bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] py-2 shadow-lg whitespace-pre-line">
        <div className="md:w-4/5 p-6 md:p-10 md:px-28 bg-white">
          <h1 className="flex justify-center items-center font-bold text-lg">
            {privacyText.privacy.title}
          </h1>
          <br />
          <div>{privacyText.privacy.description}</div>
          <br />
          <h1 className="font-bold">{privacyText.responsiblility.title}</h1>
          <br />
          <div>{privacyText.responsiblility.description}</div>
          <br />
          <h1 className="font-bold">{privacyText.peronal.title}</h1>
          <br />
          <div>{privacyText.peronal.description}</div>
          <b>a)&nbsp;</b>
          <span>{privacyText.peronal.a}</span>
          <br />
          <b>b)&nbsp;</b>
          <span>{privacyText.peronal.b}</span>
          <br />
          <b>c)&nbsp;</b>
          <span>{privacyText.peronal.c}</span>
          <br />
          <b>d)&nbsp;</b>
          <span>{privacyText.peronal.d}</span>
          <br />
          <b>e)&nbsp;</b>
          <span>{privacyText.peronal.e}</span>
          <br />
          <b>f)&nbsp;</b>
          <span>{privacyText.peronal.f}</span>
          <br />
          <br />
          <h1 className="font-bold">{privacyText.cookies.title}</h1>
          <br />
          <div>{privacyText.cookies.description}</div>
          <br />
          <h1 className="font-bold">{privacyText.security.title}</h1>
          <br />
          <div>{privacyText.security.description}</div>
          <br />
          <h1 className="font-bold">{privacyText.purpose.title}</h1>
          <br />
          <div>{privacyText.purpose.description}</div>
          <b>a)&nbsp;</b>
          <span>{privacyText.purpose.a}</span>
          <br />
          <b>b)&nbsp;</b>
          <span>{privacyText.purpose.b}</span>
          <br />
          <b>c)&nbsp;</b>
          <span>{privacyText.purpose.c}</span>
          <br />
          <br />
          <h1 className="font-bold">{privacyText.sharing.title}</h1>
          <br />
          <div>{privacyText.sharing.description}</div>
          <br />
          <h1 className="font-bold">{privacyText.arco.title}</h1>
          <br />
          <div>{privacyText.arco.description}</div>
          <br />
          <h1 className="font-bold">{privacyText.revocation.title}</h1>
          <br />
          <div>{privacyText.revocation.description}</div>
          <br />
          <h1 className="font-bold">{privacyText.changes.title}</h1>
          <br />
          <div>{privacyText.changes.description}</div>
          <br />
          <div>info@shaoyaoconsulting.com</div>
          <br />
          <div className="flex justify-center items-center">
            {privacyText.update}
          </div>
        </div>
      </div>
    </PageComponent>
  );
};

export default Privacy;
