import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const InitializeGoogleAnalytics = () => {
  const MEASUREMENT_ID = "G-LEKECDDCF1";
  const GTM_ARGS = { gtmId: "GTM-NZVTHWCH" };
  ReactGA.initialize(MEASUREMENT_ID);
  TagManager.initialize(GTM_ARGS);
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics };
