import { useEffect, useState } from "react";
import { Delivery, Interpreting, Translation } from "../../assets/hero-images";
import CallToAction from "../callToAction";

interface HeroBoxPros {
  language: string;
}

const HeroBox = (props: HeroBoxPros) => {
  const { language } = props;
  const [specializationText, setSpecializationText] = useState({
    title: "title",
    description: ["desc1", "desc2", "desc3"],
    descriptionWordChange: [
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""],
      ["", "", "", "", "", ""],
    ],
    quote: "quote",
    experience: ["exp1", "exp2", "exp3"],
  });
  const [currentWordSliderIdx, setCurrentWordSliderIdx] = useState([0, 0, 0]);
  const [showGetQuoteRedirect, setShowGetQuoteRedirect] = useState(false);
  const [currentDisplayedWord, setCurrentDisplayedWord] = useState([
    "",
    "",
    "",
  ]);

  const handleDisplayQuoteRedirect = () => {
    setShowGetQuoteRedirect(true);
    setTimeout(() => setShowGetQuoteRedirect(false), 20000);
  };

  /* 
  Language Change
  NOTE: words must not have the same starting letters in a sequence for it will break the animation.
  */
  useEffect(() => {
    switch (language) {
      case "English":
        setSpecializationText({
          title: "Cross-Cultural Solutions to Connect with China",
          description: ["Interpreting for:", "Translation of:", "Delivery of:"],
          descriptionWordChange: [
            [
              "Meetings",
              "Negotiations",
              "Conferences",
              "Audits",
              "Events",
              "Presentations",
            ],
            [
              "Reports",
              "Papers",
              "Certificates",
              "Manuals",
              "Brochures",
              "Websites",
            ],
            [
              "Lectures",
              "Workshops",
              "Courses",
              "Seminars",
              "Webinars",
              "Training",
            ],
          ],
          quote: "Get Free Quote",
          experience: [
            "10+ Years of Experience",
            "100+ Jobs Completed",
            "30+ Industries Served",
          ],
        });
        break;
      case "中文":
        setSpecializationText({
          title: "搭建中外文化桥梁，紧密连结中国",
          description: ["口译:", "笔译:", "培训:"],
          descriptionWordChange: [
            [
              "企业会议",
              "贸易谈判",
              "学术讲座",
              "工厂审计",
              "艺术演讲",
              "仪式活动",
            ],
            [
              "财务报表",
              "科学论文",
              "专利证书",
              "操作手册",
              "宣传手册",
              "官方网页",
            ],
            ["讲座", "研讨会", "课程", "在线培训", "演讲", "顾问服务"],
          ],
          quote: "立即询价",
          experience: [
            "10+ 年以上的经验",
            "100+ 多项目已完成",
            "30 多服务过的行业",
          ],
        });
        break;
      case "Español":
        setSpecializationText({
          title: "Soluciones interculturales para entenderte con China",
          description: [
            "Interpretación de:",
            "Traducción de:",
            "Impartición de:",
          ],
          descriptionWordChange: [
            [
              "juntas",
              "negociaciones",
              "conferencias",
              "auditorías",
              "presentaciones",
              "eventos",
            ],
            [
              "reportes",
              "artículos",
              "certificados",
              "manuales",
              "folletos",
              "sitios web",
            ],
            [
              "talleres",
              "conferencias",
              "seminarios",
              "cursos",
              "webinars",
              "capacitaciones",
            ],
          ],
          quote: "Pedir cotización",
          experience: [
            "10+ años de experiencia",
            "100+ proyectos completados",
            "30+ industrias atendidas",
          ],
        });
        break;
    }
  }, [language]);

  /**
   Hero word change
   */
  useEffect(() => {
    const typingAnimation = setInterval(() => {
      const newCurrentDisplayedWord = [...currentDisplayedWord];
      let checkCompletionCount = 0;
      currentWordSliderIdx.forEach((currentIdx, idx) => {
        const currentWord =
          specializationText.descriptionWordChange[idx][currentIdx];
        if (currentWord[0] !== newCurrentDisplayedWord[idx][0]) {
          newCurrentDisplayedWord[idx] = currentWord[0];
        } else if (currentDisplayedWord[idx].length < currentWord.length) {
          newCurrentDisplayedWord[idx] =
            newCurrentDisplayedWord[idx] +
            currentWord.substring(
              newCurrentDisplayedWord[idx].length,
              newCurrentDisplayedWord[idx].length + 1
            );
        } else checkCompletionCount++;
        if (checkCompletionCount === 3) {
          const pause = () => {
            const newDescriptionWordChangeIdx = [0, 0, 0];
            specializationText.descriptionWordChange.forEach((words, idx) => {
              newDescriptionWordChangeIdx[idx] =
                currentWordSliderIdx[idx] === words.length - 1
                  ? 0
                  : currentWordSliderIdx[idx] + 1;
            });
            setCurrentWordSliderIdx(newDescriptionWordChangeIdx);
          };
          setTimeout(pause, 2000);
        }
      });
      setCurrentDisplayedWord(newCurrentDisplayedWord);
    }, 150);
    return () => clearInterval(typingAnimation);
  }, [currentDisplayedWord, currentWordSliderIdx, language]);

  return (
    <div id="pre-fold-elements">
      <div
        id="hero-box-container"
        className="lg:w-[22rem] lg:max-h-lg bg-gradient-to-br lg:from-shaoyao-blue/80 lg:to-shaoyao-green/80 from-shaoyao-blue to-shaoyao-green lg:rounded-2xl border-b border-neutral-400 lg:border-b-0 overflow-auto"
      >
        <div
          id="hero-box-content"
          className="w-full h-full flex-col items-center content-start text-center"
        >
          <div className="px-6 pt-6 font-bold text-2xl">
            {specializationText.title}
          </div>
          <div className="py-2">
            <div className="w-full flex justify-around p-2">
              <img
                src={Interpreting}
                className="w-1/3 max-w-[4rem] lg:max-w-[6rem] h-full max-h-[4rem] lg:max-h-[6rem] px-2"
                alt="Interpretation icon"
              ></img>
              <img
                src={Translation}
                className="w-1/3 max-w-[4rem] lg:max-w-[6rem] h-full max-h-[4rem] lg:max-h-[6rem] px-2"
                alt="Translation icon"
              ></img>
              <img
                src={Delivery}
                className="w-1/3 max-w-[4rem] lg:max-w-[6rem] h-full max-h-[4rem] lg:max-h-[6rem] px-2"
                alt="Delivery icon"
              ></img>
            </div>
            <div className="flex text-center content-center px-2">
              {specializationText.description.map((text, idx) => {
                return (
                  <div key={String(text) + String(idx)} className="p-2 w-1/3">
                    {text}
                  </div>
                );
              })}
            </div>
            <div className="flex items-center justify-between space-x-2 px-2 text-sm">
              {specializationText.descriptionWordChange.map(
                (descriptionWords, idx) => {
                  return (
                    <div
                      key={
                        String(descriptionWords[currentWordSliderIdx[idx]]) +
                        String(idx)
                      }
                      className="w-1/3 flex items-center justify-center"
                    >
                      <div className="bg-shaoyao-light-green p-2 font-bold rounded h-6 flex items-center justify-center">
                        {currentDisplayedWord[idx]}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="p-2 pt-6">
            {!showGetQuoteRedirect && (
              <button
                aria-label="displays links to whatsapp and email"
                id="cta_quote_hero"
                className="bg-shaoyao-red w-1/2 h-10 rounded-lg shadow-2xl font-bold"
                onClick={handleDisplayQuoteRedirect}
              >
                {specializationText.quote}
              </button>
            )}
            {showGetQuoteRedirect && <CallToAction />}
            <div className="p-2 text-sm">
              {specializationText.experience.map((text, idx) => {
                return (
                  <div key={String(text) + String(idx)} className="w-full">
                    {text}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBox;
